<template>
  <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'ข้อมูลลูกค้า' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <info />
      </div>
    </vs-tab>
    <!-- <vs-tab icon-pack="feather" icon="icon-play" :label="!isSmallerScreen ? 'ประวัติการเล่น' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <playgame />
      </div>
    </vs-tab> -->
    <vs-tab icon-pack="feather" icon="icon-play-circle" :label="!isSmallerScreen ? 'ประวัติการเล่น' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <playgame-page />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-plus-circle" :label="!isSmallerScreen ? 'ประวัติการฝาก-ถอน' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <topupwd />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-users" :label="!isSmallerScreen ? 'ประวัติแนะนำเพื่อนขั้น 1' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <aff />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-users" :label="!isSmallerScreen ? 'ประวัติแนะนำเพื่อนขั้น 2' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <aff2 />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-list" :label="!isSmallerScreen ? 'ประวัติ Ranking' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <rank />
      </div>
    </vs-tab>
       <vs-tab v-if="webname.includes('SLOT')" icon-pack="feather" icon="icon-list" :label="!isSmallerScreen ? 'ประวัติโยกเงิน' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <tranfer />
      </div>
    </vs-tab>
    <vs-tab v-if="$store.state.AppActiveUser.permissions.user.action" icon-pack="feather" icon="icon-edit" :label="!isSmallerScreen ? 'แก้ไขข้อมูล' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <edit />
      </div>
    </vs-tab>
    <vs-tab v-if="$store.state.AppActiveUser.permissions.user.action" icon-pack="feather" icon="icon-send" :label="!isSmallerScreen ? 'ส่งข้อความ' : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <Message />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import info from './component/info.vue'
import topupwd from './component/topupwd.vue'
import aff from './component/afflist.vue'
import aff2 from './component/afflist2.vue'
import rank from './component/ranking'
import tranfer from './component/tranfer'
import playgame from './component/playgame.vue'
import playgamePage from './component/playgame-page.vue'
import edit from './component/edit.vue'
import Message from './component/Message.vue'

export default {
  components: {
    tranfer,
    info,
    topupwd,
    aff,
    aff2,
    playgame,
    edit,
    Message,
    playgamePage,
    rank
  },
  data () {
    return {
      webname: process.env.VUE_APP_TITLE,
      userInfo: {}
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
